import(/* webpackMode: "eager" */ "/app/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/src/css/global.scss");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/components/layout/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\",\"cyrillic\"],\"variable\":\"--font-inter\",\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["AmplitudeContextProvider"] */ "/app/src/providers/amplitude-context-provider/amplitude-context-provider.tsx");
