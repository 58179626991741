'use client';
import {add, Identify, identify, init, track, Types} from '@amplitude/analytics-browser';
import {sessionReplayPlugin} from '@amplitude/plugin-session-replay-browser';
import React, {createContext, type PropsWithChildren, useContext, useEffect} from 'react';

import {env} from '@/env';
import {getScreenResolution} from '@/providers/amplitude-context-provider/amplitude-context-helper';

type PropsType = PropsWithChildren<{
    isPreview: boolean;
}>;

type AmplitudeContextType = {
    track: (eventName: string, properties: Record<string, unknown>) => void;
};

const LOG_LEVEL = Types.LogLevel.Warn;
const SESSION_REPLAY_SAMPLE_RATE = 1;

export const AmplitudeContext = createContext<AmplitudeContextType>({} as AmplitudeContextType);

function trackEvent(eventName: string, properties: Record<string, unknown>) {
    track(eventName, properties);
}

export function AmplitudeContextProvider(props: PropsType): React.JSX.Element {
    const {children, isPreview} = props;

    useEffect(() => {
        const AMPLITUDE_API_KEY =
            env[window.location.host]?.NEXT_PUBLIC_AMPLITUDE_API_KEY ?? process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY ?? '';

        if (AMPLITUDE_API_KEY && !isPreview) {
            init(AMPLITUDE_API_KEY, {
                logLevel: LOG_LEVEL,
                defaultTracking: {
                    sessions: true,
                    fileDownloads: false,
                    formInteractions: false,
                    pageViews: false,
                    attribution: false,
                },
            });

            add(sessionReplayPlugin({sampleRate: SESSION_REPLAY_SAMPLE_RATE}));

            const identityEvent = new Identify();

            identityEvent.setOnce('screenResolution', getScreenResolution());
            identify(identityEvent);
        }
    }, [isPreview]);

    return <AmplitudeContext.Provider value={{track: trackEvent}}>{children}</AmplitudeContext.Provider>;
}

export function useAmplitudeContext(): AmplitudeContextType {
    return useContext(AmplitudeContext);
}
